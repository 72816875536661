.candelyhead {
  margin-left: 64px;
}

@media only screen and (max-width: 600px) {
  .candelyhead {
    margin-left: 40px;
    width: 83%;
  }
  .welcomeP {
    width: 83%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .welcomeP {
    width: 83%;
  }

  .bannerContent h1 {
    width: 92%;
  }
}

@media only screen and (max-width: 600px) {
  .paperModal {
    height: 80% !important;
  }
  .confirmationCode {
    font-size: 12px;
  }
  .dateH2 {
    margin: 2px !important;
    margin-top: 16px !important;
  }
}
.btnback {
  border-radius: 16px;
  border-radius: 8px;
  padding: 0.5em 1em;
  color: #f07167;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 45px;
}
